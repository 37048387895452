import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";
import AboutProfileList from "../../components/about-profile-list";

import WithService from "../../service/withService";
import { SearchProfile } from "../../components/search-profiles";
import { useSearchProfile } from "../../hooks/useSearchProfile";

const ActiveSistersList = ({ loading, response, error }) => {
  const talonProps = useSearchProfile({
    data: response?.people,
  });

  const { filteredData, filter, data, searchLength } = talonProps;

  return (
    <>
      {data?.length > 0 && (
        <SearchProfile
          onChange={filter}
          actualData={data}
          searchLength={searchLength}
        />
      )}
      <div className="row mb-4">
        <AboutProfileList loading={loading} response={filteredData} />
      </div>
    </>
  );
};

const Sisters = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Sisters in active service" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">Sisters in active service</h3>
              <div className="row">
                <WithService endpoint="people-by-head/people-sister-active">
                  <ActiveSistersList />
                </WithService>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Sisters;
